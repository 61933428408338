import React from 'react'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Button from '../bvv-styleguide/components/button/Button'

import notFoundImage from '../images/404.svg'

import './404.scss'

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="not-found-page">
      <div className="not-found-page__wrapper">
        <img src={notFoundImage} alt="404" />
        <h2>PAGE NOT FOUND</h2>
        <p>
          The page you are looking for does not exist, has been removed or you
          accidentally mistyped the name.
        </p>
        <Button text="Back to home" link="/" />
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
